<template>
  <div class="elv-landing-activation-container">
    <DetailHeader id="header" />
    <div v-loading="isLoading" class="elv-landing-activation-content">
      <p class="elv-landing-activation-content-svg">
        <SvgIcon name="hourglass" width="32" height="32" />
        <span>{{ t('title.activationRequired') }}</span>
      </p>
      <p>{{ t('message.createProjectSuccessTipInfo') }}</p>
      <p class="elv-landing-activation-content-tip">{{ t('message.receiveEmailTipInfo') }}</p>
      <p>
        {{ t('message.resendEmailTipInfo') }},<span
          class="elv-landing-activation-content-send-email"
          @click="onClickSendEmail"
          >{{ t('message.resendEmailLinkInfo') }}</span
        >
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { isEmpty } from 'lodash-es'
import { ElMessage } from 'element-plus'
import ProjectApi from '@/api/ProjectApi'
import { useGlobalStore } from '@/stores/modules/global'
import { useProjectStore } from '@/stores/modules/project'
import DetailHeader from '../../Financials/components/DetailHeader.vue'
import { ProjectPermissionLevel, ProjectReviewStatus } from '#/ProjectTypes'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const globalStore = useGlobalStore()
const projectStore = useProjectStore()

const isLoading = ref(false)

const unActiveFreeProject = computed(() => {
  const projectDetail = globalStore.projectDetail((route.query.projectId as string) || '')
  if (isEmpty(projectDetail)) {
    return globalStore.projectList.find(
      (project) =>
        project.plan === ProjectPermissionLevel.FREE &&
        project.projectReview?.status === ProjectReviewStatus.UNACTIVATION
    )
  }
  return projectDetail
})

/**
 * 重新发送邮箱激活链接
 */
const onClickSendEmail = async () => {
  if (!unActiveFreeProject.value) return
  try {
    isLoading.value = true
    await ProjectApi.sendEmail(unActiveFreeProject.value.projectId || '')
    ElMessage.success(t('message.emailSendSuccessTipInfo'))
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    isLoading.value = false
  }
}

watch(
  () => route,
  async (newValue) => {
    if (newValue.name === 'landing-activate') {
      const currentProjectId = route.query?.projectId as string
      if (currentProjectId) {
        const currentProject = globalStore.projectDetail(currentProjectId)
        if (currentProject) {
          if (currentProject.projectReview?.status === ProjectReviewStatus.UNACTIVATION) {
            try {
              isLoading.value = true
              await Promise.all([
                ProjectApi.activateFreeProject(currentProjectId),
                globalStore.fetchProjectList(),
                projectStore.fetchProjectDetail(currentProjectId)
              ])

              ElMessage.success(t('message.activationSuccessfully'))
              router.push({ name: 'entity-settings', params: { projectId: currentProjectId } })
            } catch (error: any) {
              ElMessage.error(error.message)
            } finally {
              isLoading.value = false
            }
            return
          }
          if (currentProject.entityList?.length) {
            router.push({
              name: 'entity',
              params: {
                entityId: currentProject?.entityList[0].entityId
              }
            })
            return
          }
          router.push({ name: 'entity-settings', params: { projectId: currentProject.projectId } })
        }
        router.push({ path: '/' })
      }
    }
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss" scoped>
.elv-landing-activation-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  .elv-chain-nav-container {
    height: 48px;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #dde1e6;
  }

  .elv-landing-activation-content {
    width: 636px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #1e2024;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-top: 150px;

    p {
      margin-bottom: 24px;

      .elv-landing-activation-content-send-email {
        color: #1753eb;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .elv-landing-activation-content-svg {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #0e0f11;
      font-size: 20px;
      font-weight: 600;

      svg {
        margin-bottom: 16px;
      }
    }

    .elv-landing-activation-content-tip {
      font-weight: 600;
    }
  }
}
</style>
